import styles from "./card.module.css";
import { Link } from "../../Link";
import ContentLoader from "react-content-loader";

import { trackEvent } from "../../../utils/event-tracker";

import Avatar from "../../../assets/images/icons/avatar-64.svg";
import { FC } from "react";

function truncateText(str: string, maxLength = 95) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 1) + "…";
  } else {
    return str;
  }
}

type SkeletonProps = {
  loading: true;
};
type CardProps = {
  loading: false;
  title: string;
  author: string;
  copy: string;
  redirectTo: string;
  AvatarComponent?: FC;
};

export const Card = (props: { dataTestid?: string } & (SkeletonProps | CardProps)) => {
  const { dataTestid, loading } = props;

  if (loading) {
    return (
      <div className={styles["card-background"]}>
        <ContentLoader
          speed={5}
          width={205}
          height={156}
          viewBox="0 0 205 156"
          backgroundColor="#11111117"
          foregroundColor="#11111157"
          data-testid={dataTestid}
          uniqueKey={process.env.NODE_ENV === "test" ? "componentCardSkeleton" : undefined}
        >
          <rect x="0" y={4} rx="5" ry="5" width="160" height="24" />
          <rect x="0" y={50} rx="3" ry="3" width="170" height="13" />
          <rect x="0" y={70} rx="3" ry="3" width="190" height="13" />
          <rect x="0" y={90} rx="3" ry="3" width="130" height="13" />
          <circle cx="15" cy="140" r="15" />
          <rect x="37" y="135" rx="2" ry="2" width="70" height="12" />
        </ContentLoader>
      </div>
    );
  }

  const { title, author, redirectTo, AvatarComponent, copy } = props;

  function handleClick() {
    trackEvent({
      name: "khub_hp_card_clicked",
      title,
      author,
    });
  }

  return (
    <Link href={redirectTo} onClick={handleClick} dataTestid={dataTestid}>
      <div className={styles["card-background"] + " hover-shadow"}>
        <div className="d-flex flex-column flex-grow-1 justify-content-between" style={{ minHeight: `${206 - 48}px` }}>
          <div className="position-relative d-flex flex-column flex-grow-1 justify-content-start">
            <div className={styles["card-title"]}>{truncateText(title)}</div>
            <div className={styles["card-copy"]}>{truncateText(copy)}</div>
          </div>
          <div className="d-flex flex-grow-1 align-items-center">
            {AvatarComponent ? <AvatarComponent /> : <Avatar width={29} height={29} viewBox="12 12 40 40" />}
            <span className={styles["card-author"]}>{author}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};
