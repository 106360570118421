import * as React from "react";
import { SVGProps } from "react";
const Cube = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h24v24H0z" />
      <path stroke="#000" d="M5.667 8.078v7.838L12 19l6.333-3.084V8.078L12 5z" />
      <path stroke="#000" strokeWidth={1.1} d="M18.333 8.078 12 10.79 5.667 8.078M12 19v-8.21" />
    </g>
  </svg>
);
export default Cube;
