import ContentLoader from "react-content-loader";
import { User } from "..";
import { getComponentUrl } from "../../utils/helpers/utils";
import { trackEvent } from "../../utils/event-tracker";
import CategoryBadge from "../category-badge";
import MarkdownRenderer from "../MarkdownRenderer";
import styles from "./card.module.css";
import { Link } from "../Link";

const DEFAULT_WIDTH = 368;

type CardProps = {
  title?: string;
  info?: string;
  badge: { label: string };
  description?: string;
  user: { name?: string | null; avatar: string };
  dataTestid?: string;
  componentType?: string;
  loading?: boolean;
  border?: string;
  redirectUrl?: string;
  hideExternalLinkIcon?: boolean;
  preInfo?: string;
  width?: string;
  icon?: string;
  onClick?: () => void;
  removeHover?: string;
};

export const Card = ({
  title = "",
  info = "",
  badge = {
    label: "",
  },
  description = "",
  user = {
    name: "",
    avatar: "",
  },
  dataTestid,
  componentType = "",
  loading,
  redirectUrl,
  hideExternalLinkIcon,
  preInfo,
  width,
  icon,
  onClick,
}: CardProps) => {
  const { label } = badge;
  const { name } = user;

  const redirectTo = redirectUrl ? redirectUrl : getComponentUrl({ name: title, component_type: componentType });

  function handleClick() {
    trackEvent({
      name: "component-card_clicked",
      title,
      type: componentType,
    });
  }

  const innerCard = (
    <div className="d-flex flex-column gap-7 p-4">
      <div className="d-flex flex-column">
        {preInfo && (
          <div className="text-capitalize py-0 my-0 small ls-2-5">
            {icon && icon}
            {preInfo}
          </div>
        )}

        <div className="d-flex flex-grow-1 position-relative">
          <div className="text-capitalize py-0 my-0 fs-5 ls-0-3 lh-1-875 text-truncate">{title}</div>
        </div>

        {info && <div className="d-flex align-items-center text-capitalize py-0 my-0 small ls-0-3 lh-1-25">{info}</div>}
      </div>

      <div className="d-flex flex-column">
        {description && (
          <div className="d-flex flex-grow-1 flex-column gap-3">
            <CategoryBadge category={label} color={"light"} />
            <div className="text-capitalize ls-1 small line-clamp-2 lh-2-875">
              <MarkdownRenderer content={description} />
            </div>
          </div>
        )}

        {name && <User name={name} dataTestid={name} />}
      </div>
    </div>
  );

  return loading ? (
    <div className="w-fit bg-white rounded-5">
      <ContentLoader
        speed={5}
        width={width ? width : DEFAULT_WIDTH}
        height={252}
        viewBox="0 0 320 252"
        backgroundColor="#11111117"
        foregroundColor="#11111157"
      >
        <rect x="0" y="15" rx="5" ry="5" width="233" height="22" />
        <rect x="0" y="55" rx="3" ry="3" width="63" height="5" />
        <rect x="0" y="106" rx="3" ry="3" width="124" height="20" />
        <rect x="0" y="149" rx="4" ry="4" width="320" height="10" />
        <rect x="0" y="172" rx="4" ry="4" width="86" height="10" />
        <circle cx="15" cy="220" r="15" />
        <rect x="34" y="218" rx="2" ry="2" width="70" height="5" />
      </ContentLoader>
    </div>
  ) : (
    <Link
      data-testid={dataTestid}
      href={redirectTo}
      className={`hover-shadow ${styles["card-base"]}`}
      onClick={onClick || handleClick}
      hideExternalLinkIcon={hideExternalLinkIcon}
    >
      {innerCard}
    </Link>
  );
};
